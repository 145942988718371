@mixin curtain {
    overflow: hidden;
    will-change: height;
    
    &.is-hidden {
        display: none;
    }
    
    &:not(.is-transitioned) {
        height: 0;
    }
    
    &.has-transitions {
        transition: height 250ms;
    }
    
    &:focus {
        outline: none;
    }
}

@include media-generate('.curtain') {
    @include curtain;
}

.curtain__icon {
    transition: transform 250ms;
}
.curtain__head.is-active .curtain__icon {
    transform: rotate(45deg);
}
.curtain__head.is-active .curtain__icon--mirror {
    transform: rotate(180deg);
}
