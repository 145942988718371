.o-fluid-object {
	position: relative;
}

@include media-generate('.o-fluid-object__item') {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}