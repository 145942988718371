$_blockquote-padding: space--x();

.blockquote {
    @include reset(blockquote);
    font-size: 32px;
    line-height: 42px;
    padding-left: $_blockquote-padding;
    padding-right: $_blockquote-padding;
    
    &::before,
    &::after {
        border: 0 solid transparent;
        content: "";
        display: block;
        height: 0;
        width: 0;
    }
    &::before {
        margin-left: -$_blockquote-padding;
        border: {
            top-width: space--h();
            top-color: map-get($base__colors, 'sunshine');
            right-width: space--h();
        }
    }
    &::after {
        margin-left: auto;
        margin-right: -$_blockquote-padding;
        border: {
            bottom-width: space--h();
            bottom-color: map-get($base__colors, 'sunshine');
            left-width: space--h();
        }
    }
}