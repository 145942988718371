$c-form__border-width: 2px;
$c-form__padding-bump: 2px;

.form__label {
    display: block;
    font-weight: map-get($base__font-weights, bold);
    margin-bottom: space--s(1/2);
}
.form__label--required::after {
    content: " *";
}
.form__input {
    @include reset(input);
}
.form__select {
    height: $base__line-height + 2 * space--t($addend: $c-form__padding-bump);
}
.form__input,
.form__select {
    background-color: map-get($base__colors, 'white');
    border: $c-form__border-width solid map-get($base__colors--overlay, 'white');
    line-height: inherit;
    padding: space--t($addend: $c-form__padding-bump - $c-form__border-width) space--s($addend: $c-form__padding-bump - $c-form__border-width);
    transition: border-color 250ms;
}
.form__input--naked {
    border-color: transparent;
}
.form__input--positive {
    border-color: map-get($base__colors, 'white');

    &:hover {
        border-color: map-get($base__colors, 'fall');
    }
}
.form__input--negative {
    background-color: map-get($base__colors, 'black');
    border-color: map-get($base__colors--veillay, 'steel');
    color: map-get($base__colors, 'white');

    &:hover,
    &:focus {
        border-color: map-get($base__colors, 'white');
    }
}
.form__input--join--l {
    border-left: none;
}
.form__input--join--r {
    border-right: none;
}

.form__autocomplete {
    // border: $c-form__border-width solid map-get($base__colors, 'fall');
    // border-top-color: map-get($base__colors--overlay, 'iron');
    background-color: map-get($base__colors, 'white');
    margin-top: -$c-form__border-width;

    &,
    & > * {
        padding: space--s(1/2, $c-form__padding-bump - $c-form__border-width) space(1/2, $c-form__padding-bump - $c-form__border-width);
    }
}
.form__autocomplete--negative {
    background-color: map-get($base__colors--overlay, 'mine-shaft');
    border-color: map-get($base__colors, 'white');
    border-top-color: map-get($base__colors--veillay, 'mine-shaft');
}
.form__autocomplete--join--l {
    border-left-color: transparent;
    border-bottom: none;
    box-shadow: -$c-form__border-width 0 0 0 map-get($base__colors, 'fall'),
                0 $c-form__border-width 0 0 map-get($base__colors, 'fall'),
                -$c-form__border-width $c-form__border-width 0 0 map-get($base__colors, 'fall');
}
.form__autocomplete--join--r {
    border-right-color: transparent;
    border-bottom: none;
    box-shadow: $c-form__border-width 0 0 0 map-get($base__colors, 'fall'),
                0 $c-form__border-width 0 0 map-get($base__colors, 'fall'),
                $c-form__border-width $c-form__border-width 0 0 map-get($base__colors, 'fall');
}

.form__check {
    @include typo--body(s);
    @include space--l(padding-left);
    background-color: map-get($base__colors--overlay, 'white');
    display: block;
    padding: space--s() space--s(3/2);
    padding-left: space--x(1, space--t(-1));
    position: relative;
    transition: background-color 250ms;

    &:not(.form__check--disabled) {
        cursor: pointer;

        &:hover {
            background-color: map-get($base__colors--overlay, 'iron');
        }
    }
}
.form__check--negative {
    background-color: map-get($base__colors, 'black');
    color: map-get($base__colors, 'white');

    &:not(.form__check--disabled):hover {
        background-color: map-get($base__colors--overlay, 'steel');
    }
}
.form__check--disabled {
    opacity: .5;
}

.form__check__input {
    display: inline-block;
    margin-left: space--l(-1);
    text-align: center;
    width: space--l();
}
