.icon {
    fill: currentColor;
    vertical-align: middle;

    &:not([height]) {
        height: 1em;
    }
    &:not([width]) {
        width: 1em;
    }
}
