[data-banner]:not([data-banner=""]) {
    position: relative;
    
    &::after {
        color: map-get($base__colors, 'white');
        background-color: rgba(map-get($base__colors, 'primary'), .75);
        content: attr(data-banner);
        padding: .5em .75em;
        position: absolute;
            left: 0;
            right: 0;
            top: 50%;
        text-align: center;
        text-transform: uppercase;
        transform: translateY(-50%);
        z-index: layer(above);
    }
}
