.quote {
    @include reset(blockquote);
}
.quote__body {
    border-left: space--s(1/2) solid map-get($base__colors, 'sunshine');
    font-style: italic;
    padding: space--s() 0 space--s() space();

    &::after,
    &::before {
        content: '"';
    }
}
.quote__cite {
    font-style: normal;
}