@viewport {
    zoom: 1.0;
    width: extend-to-zoom;
}


//**
//* Global variables
//*/

// font and spacing

$base__font-size: 18px !default;
$base__font-size--small: 15px !default;
$base__font-size--large: 21px !default;
$base__line-height: 26px !default;
$base__line-height--small: 23px !default;
$base__line-height--large: 29px !default;
$base__space-unit: 20px !default;
$base__font-family: "futura-pt", "Futura", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base__font-family--head: $base__font-family !default;
$base__font-weights: ('normal': normal, 'medium': 500, 'bold': bold) !default;

// colours

$base__colors: (
    'white': #FFFFFF,
    'black-haze': #F7F7F7,
    'light-gray': #ECECEC,
    'mine-shaft': #333333,
    'black': #000000,
    
    'primary': #EE2964,
    'secondary': #F58220,
) !default;

$base__colors--overlay: (
    'white': rgba(black, .1),
    'iron': rgba(black, .2),
    'magnesium': rgba(black, .3),
    'steel': rgba(black, .6),
    'mine-shaft': rgba(black, .8),
) !default;

$base__colors--veillay: (
    'black': rgba(white, .1),
    'mine-shaft': rgba(white, .2),
    'steel': rgba(white, .4),
    'iron': rgba(white, .8),
    'white': rgba(white, .9),
) !default;

$base__color: #333333 !default;
$base__color--head: $base__color !default;
$base__color--link: $base__color !default;
$base__color--focus: map-get($base__colors--overlay, steel) !default;
$base__background-color: #FFFFFF !default;

// breakpoints

// webflow.com breakpoints
// $base__breakpoint: (
//     s: 479px,
//     m: 767px,
//     l: 991px,
//     x: null,
// ) !default;

$base__breakpoint: (
    s: 500px,
    m: 850px,
    l: 1440px,
    x: null,
) !default;

// NOTE: a breakpoint with true as a second value means the style will be included but not wrapped in any media query

$base__breakpoints: (
    s:    '(max-width: ' + (map-get($base__breakpoint, s)) + ')',
    m-up: '(min-width: ' + (map-get($base__breakpoint, s) + 1) + ')',
    m-to: '(max-width: ' + (map-get($base__breakpoint, m)) + ')',
    m:    '(min-width: ' + (map-get($base__breakpoint, s) + 1) + ') and (max-width: ' + (map-get($base__breakpoint, m)) + ')',
    l-up: '(min-width: ' + (map-get($base__breakpoint, m) + 1) + ')',
    l-to: '(max-width: ' + (map-get($base__breakpoint, l)) + ')',
    l:    '(min-width: ' + (map-get($base__breakpoint, m) + 1) + ') and (max-width: ' + (map-get($base__breakpoint, l)) + ')',
    x:    '(min-width: ' + (map-get($base__breakpoint, l) + 1) + ')',
    
    h-m:  '(min-height: ' + (map-get($base__breakpoint, s) + 1) + ')',
    h-l:  '(min-height: ' + (map-get($base__breakpoint, m) + 1) + ')',
    h-x:  '(min-height: ' + (map-get($base__breakpoint, l) + 1) + ')'
) !default;
