.card {
    @include box();

    transition: {
        duration: 250ms;
        property: color, transform;
    }

    &.is-active {
        background-color: map-get($base__colors, 'primary');
        
        &,
        :link, :visited,
        h1, h2, h3, h4, h5, h6,
        .h1, h2, h3, h4, h5, h6 {
            color: map-get($base__colors, 'white');
        }

        :link, :visited {
            &:hover,
            &:focus {
                border-bottom-color: map-get($base__colors, 'white');
            }
        }
    }
}