$o-slider--overflow-s: space(3);
$o-slider--overflow-m: space(6);
$o-slider--overflow-l: space--h(6);
$o-slider--scrollbar-size: 0;

.o-slider {
    display: grid;
    grid-auto-columns: calc(100% - #{$o-slider--overflow-s});
    grid-auto-flow: column;
    grid-gap: space--s(1.5);

    -webkit-overflow-scrolling: touch;
    overflow-x: auto;

    @if ($o-slider--scrollbar-size > 0) {
        margin-bottom: -$o-slider--scrollbar-size;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: $o-slider--scrollbar-size;
            
            &-thumb {
                border-radius: 3.5px;
                background-color: rgba(0, 0, 0, .5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
            }
        }
    }
    
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100%);

    @include media('m') {
        grid-auto-columns: calc(100% - #{$o-slider--overflow-m});
    }
    @include media('l-up') {
        grid-auto-columns: calc(100% - #{$o-slider--overflow-l});
        grid-gap: space(2);
    }
}

.o-slider__item {
    scroll-snap-align: center;
}

:root.has-js {
    @include media('m-to') {
        .o-slider__item:not(.is-active) {
            display: initial;
        }
    }
    @include media('l-up') {
        .o-slider {
            grid-gap: 0;
            grid-auto-columns: 100%;
            
            overflow: hidden;
            position: relative;

            @if ($o-slider--scrollbar-size > 0) {
                margin-bottom: 0;
            }
        }
    }
}
