:link,
:visited,
.b-link {
    transition: {
        duration: 250ms;
        property: color, border-color;
    }
    text-decoration: none;
    color: $base__color--link;
    border-bottom: 1px solid mix($base__color--link, map-get($base__colors, 'white'), 25%);
    padding-bottom: 1px;
    
    &:hover,
    &:focus {
        border-bottom-color: $base__color--focus;
    }
    @if $base__color--focus != $base__color--link {
        &:hover,
        &:focus {
            color: $base__color--focus;
        }
    }
}
.b-link--call {
    border: 1px solid currentColor;
    display: inline-block;
    padding: space--s() space() space--s(1, -2);
    text-transform: uppercase;
    transition-property: opacity;

    &,
    &:hover,
    &:focus {
        border-color: currentColor;
        color: inherit;
    }
    &:hover,
    &:focus {
        opacity: .75;
    }
}
.b-link--pill {
    background-color: map-get($base__colors, 'white');
    border-radius: space();
    display: inline-block;
    padding: space--t() space--l();
}
.b-link--card {
    border-bottom: none;
    font-weight: inherit;
    padding-bottom: 0;
    transition-property: transform;

    &,
    &:hover,
    &:focus {
        color: inherit;
    }
    &:hover,
    &:focus {
        transform: translateY(space--t(-1));
    }
}
.b-link--reset,
.b-links--reset :link,
.b-link--icon,
.b-links--icon :link {
    border-bottom: none;
    color: inherit;
    font-weight: inherit;
    padding-bottom: 0;
    transition: none;
}
.b-link--title,
.b-links--title :link {
    border-bottom: none;
    font-weight: inherit;
    padding-bottom: 0;
    
    @if $base__color--link != $base__color--head {
        &:not(:hover):not(:focus) {
            color: inherit;
        }
    }
}
.b-link--negative,
.b-links--negative :link {
    border-color: map-get($base__colors, 'white');
    
    &,
    &:hover,
    &:focus {
        color: map-get($base__colors, 'white');
    }
    &:hover,
    &:focus {
        color: rgba(map-get($base__colors, 'white'), .75);
        border-color: rgba(map-get($base__colors, 'white'), .75);
    }
}
.b-link--icon,
.b-links--icon :link {
    transition: 250ms opacity;
    
    &:hover,
    &:focus {
        opacity: .75;
    }
}
.b-link--important {
    color: map-get($base__colors, fall);
}
