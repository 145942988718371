.overcard__media > * {
    width: 100%;
}
.overcard__body {
    padding-top: space();
}
@include media('m-to') {
    .overcard__media {
        @include ratio(16 9);

        * > {
            height: auto;
        }
    }
}
@include media('l-up') {
    .overcard,
    .overcard__body {
        position: relative;
    }
    .overcard {
        display: grid;
        overflow: hidden;
    }
    .overcard__media > * {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
    }
    .overcard__body {
        @include box;
        background-color: rgba(map-get($base__colors, 'mine-shaft'), .75);
        min-height: 8 * (map-get($base__breakpoint, 'l') - 2 * $o-container--padding-l) / 16;
        padding-top: space--x();
        width: calc(1 / 3 * 100%);

        &:not(._) {
            margin-left: 0;
        }
    }
}
