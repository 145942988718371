@function pow($x, $n) {
    $ret: 1;
    
    @if $n >= 0 {
        @for $i from 1 through $n {
            $ret: $ret * $x;
        } 
    } @else {
        @for $i from $n to 0 {
            $ret: $ret / $x;
        }
    }
    
    @return $ret;
}

@mixin typo--head($size: 0, $important: false) {
    
    $imp: if($important, !important, null);
    $base: $base__font-size;
    
    color: $base__color--head $imp;
    font-family: $base__font-family--head $imp;
    font-weight: map-get($base__font-weights, if(index(0 1 2 3, $size), normal, bold)) $imp;
    
    @if index(0 1 2 3, $size) {
        $ratio: $ratio--major-third;
        
        font-size: $base * pow($ratio, 4 - $size) $imp;
        line-height: $base * pow($ratio, 4 - $size) + $base / pow($ratio--minor-seventh, 4 - $size) $imp;
        letter-spacing: -0.05em / $ratio--double-octave $imp;
        
        @include media(l-up) {
            $ratio: $ratio--augmented-fourth;
        
            font-size: $base * pow($ratio, 4 - $size) $imp;
            line-height: $base * pow($ratio, 4 - $size) + $base / pow($ratio--major-tenth, 4 - $size) $imp;
        }
    }
    @if index(4 5 6, $size) {
        letter-spacing: normal $imp;
    }
    @if index(4, $size) {
        @include typo--body(m, $important);
    }
    @if index(5 6, $size) {
        @include typo--body(s, $important);
    }
}
@mixin typo--body($size: m, $important: false) {
    
    $imp: if($important, !important, null);
    
    @if $size == s {
        font-size: $base__font-size--small $imp;
        line-height: $base__line-height--small $imp;
    }
    @if $size == m {
        font-size: $base__font-size $imp;
        line-height: $base__line-height $imp;
    }
    @if $size == l {
        font-size: $base__font-size--large $imp;
        line-height: $base__line-height--large $imp;
    }
}