// .u-space {
    @include media-generate(".u-space") {
        @include space($important: true);
    }
    @include media-generate(".u-space--n") {
    // &--n {
        @include space--n($important: true);
    }
    @include media-generate(".u-space--t") {
    // &--t {
        @include space--t($important: true);
    }
    @include media-generate(".u-space--s") {
    // &--s {
        @include space--s($important: true);
    }
    @include media-generate(".u-space--m") {
    // &--m {
        @include space--m($important: true);
    }
    @include media-generate(".u-space--l") {
    // &--l {
        @include space--l($important: true);
        
        &.u-space--bump {
            @include media(h-m) {
                @include space--l($multiplier: 2, $important: true);
            }
            @include media(h-l) {
                @include space--l($multiplier: 3, $important: true);
            }
            @include media(h-x) {
                @include space--l($multiplier: 4, $important: true);
            }
        }
    }
    @include media-generate(".u-space--x") {
    // &--x {
        @include space--x($important: true);
        
        &.u-space--bump {
            @include media(h-m) {
                @include space--x($multiplier: 2, $important: true);
            }
            @include media(h-l) {
                @include space--x($multiplier: 3, $important: true);
            }
            @include media(h-x) {
                @include space--x($multiplier: 4, $important: true);
            }
        }
    }
    @include media-generate(".u-space--h") {
    // &--h {
        @include space--h($important: true);
        
        &.u-space--bump {
            @include media(h-m) {
                @include space--h($multiplier: 2, $important: true);
            }
            @include media(h-l) {
                @include space--h($multiplier: 3, $important: true);
            }
            @include media(h-x) {
                @include space--h($multiplier: 4, $important: true);
            }
        }
    }
    
    @include media-generate(".u-space--t-") {
    // &--t- {
        @include space--t($multiplier: -1, $important: true);
    }
    @include media-generate(".u-space--s-") {
    // &--s- {
        @include space--s($multiplier: -1, $important: true);
    }
    @include media-generate(".u-space--m-") {
    // &--m- {
        @include space--m($multiplier: -1, $important: true);
    }
    @include media-generate(".u-space--l-") {
    // &--l- {
        @include space--l($multiplier: -1, $important: true);
        
        &.u-space--bump {
            @include media(h-m) {
                @include space--l($multiplier: -2, $important: true);
            }
            @include media(h-l) {
                @include space--l($multiplier: -3, $important: true);
            }
            @include media(h-x) {
                @include space--l($multiplier: -4, $important: true);
            }
        }
    }
    @include media-generate(".u-space--x-") {
    // &--x- {
        @include space--x($multiplier: -1, $important: true);
        
        &.u-space--bump {
            @include media(h-m) {
                @include space--x($multiplier: -2, $important: true);
            }
            @include media(h-l) {
                @include space--x($multiplier: -3, $important: true);
            }
            @include media(h-x) {
                @include space--x($multiplier: -4, $important: true);
            }
        }
    }
    @include media-generate(".u-space--h-") {
    // &--h- {
        @include space--h($multiplier: -1, $important: true);
        
        &.u-space--bump {
            @include media(h-m) {
                @include space--h($multiplier: -2, $important: true);
            }
            @include media(h-l) {
                @include space--h($multiplier: -3, $important: true);
            }
            @include media(h-x) {
                @include space--h($multiplier: -4, $important: true);
            }
        }
    }
// }