.slider__prev,
.slider__next,
.slider__peeps,
.slider__progress {
    position: absolute;
    z-index: 1;
}
.slider__prev,
.slider__next {
    top: 50%;
    transform: translateY(-50%);
}
.slider__prev {
    left: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
.slider__next {
    right: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}
.slider__peeps {
    top: space--s();
    left: 50%;
    transform: translateX(-50%);
}
.slider__peep,
.slider__prev,
.slider__next {
    cursor: pointer;
    padding: space--s();

    &:focus {
        outline: none;
    }
}
.slider__peep {
    border-radius: 50%;

    &:focus {
        opacity: .7;
    }
    &.is-active {
        background-color: map-get($base__colors--veillay, 'iron');
    }
}
.slider__progress {
    font-weight: map-get($base__font-weights, bold);
}