@include media('l-up') {
    .list-separator {
        @include grid();

        > * + * {
            padding-left: space--s();
            margin-left: space--s();
            border-left: 1px solid $base__color;
        }
    }
}
