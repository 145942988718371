.nav-link {
    border-bottom: none;
    font-weight: map-get($base__font-weights, 'normal');
    padding-bottom: 0;
    transition: {
        duration: 250ms;
        property: opacity;
    }
    
    &:not(.nav-link--sublink) {
        text-transform: uppercase;
    }
    
    &,
    &:hover,
    &:focus {
        color: map-get($base__colors, 'white');
    }

    &:hover,
    &:focus {
        opacity: .75;
    }
}
.nav-link__submenu {
    padding: {
        left: space--s();
        right: space--s();
    }
}

@include media(l-up) {
    .nav-link__subnav {
        position: relative;
    }
    .nav-link__submenu {
        min-width: 10em;
        position: absolute;
        z-index: layer('above');
    }
}

@include media-generate('.nav-link--button') {
    background-color: map-get($base__colors--veillay, 'mine-shaft');
    display: inline-block;
    padding: space--s(2/3) space--s() space--s(2/3, -2);
    transition: {
        duration: 250ms;
        property: background-color, border-bottom-color;
    }
    
    &.is-active {
        background-color: map-get($base__colors--overlay, 'iron');
    }
}
