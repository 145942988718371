.box {
    @include box();
    
    padding: {
        top: $o-container--padding-s;
        right: $o-container--padding-s;
        left: $o-container--padding-s;
    }
}
.box__bleed {
    margin: {
        left: -$o-container--padding-s;
        right: -$o-container--padding-s;
    }
}

.box--c {
    border: 1px solid map-get($base__colors, 'light-gray');
}

@each $_name, $_color in $base__colors {
    .box--c-#{$_name} {
        border-color: $_color;
    }
}
