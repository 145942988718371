button,
input:not([type="password"]),
optgroup,
select,
textarea {
    font-family: inherit;
}
input[type="button"],
input[type="submit"],
input[type="reset"] {
    white-space: normal;
}
