.message {
    cursor: pointer;
}
.message--modal {
    background-color: map-get($base__colors--veillay, 'iron');
    position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    transition: opacity 250ms;
    z-index: layer(top);

    &:not(.is-active) {
        opacity: 0;
    }
}

.message__close {
    position: absolute;
        right: space--s();
        top: space--s();
}

.message__body {
    @include box();
    z-index: layer(top);

    @each $_name, $_color in $base__colors {
        &--c-#{$_name} {
            background-color: rgba($_color, .95);
        }
    }
}
.message__body:not(.message__body--modal) {
    position: fixed;
    width: 100%;
    transition: transform 250ms;
}
.message.is-active .message__body:not(.message__body--modal) {
    transform: translateY(0);
}

.message__body:not(.message__body--top):not(.message__body--modal) {
    bottom: 0;
    transform: translateY(100%);
}
.message.is-active .message__body:not(.message__body--top):not(.message__body--modal) {
    &:hover {
        transform: translateY(10%);
    }
}

.message__body.message__body--top:not(.message__body--modal) {
    top: 0;
    transform: translateY(-100%);
}
.message.is-active .message__body.message__body--top:not(.message__body--modal) {
    &:hover {
        transform: translateY(-10%);
    }
}

.message__body--modal {
    @include middle(true);

    width: calc(100% - #{space--l()});

    @include media(m-up) {
        width: calc(100% - #{space--x(2)});
    }

    max-width: map-get($base__breakpoint, 's');
}
