body {
    @include typo--body(m);
    text-rendering: optimizeLegibility;
    
    color: $base__color;
    background-color: $base__background-color;
    
    @if $base__font-family != sans-serif {
        font-family: $base__font-family;
    }
    @if map-get($base__font-weights, normal) != normal {
        font-weight: map-get($base__font-weights, normal);
    }
}