$c-scroll-nav__border--width: 3px;
$c-scroll-nav__border--color: map-get($base__colors, 'light-gray');
$c-scroll-nav__z-index: layer(modal);

.scroll-nav {
    background-color: map-get($base__colors--veillay, 'white');
    border-bottom: $c-scroll-nav__border--width solid $c-scroll-nav__border--color;
    
    @include media(s) {
        display: none;
    }
    @include media(m-up) {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: $c-scroll-nav__z-index;
    }
}
.scroll-nav__actions {
    @include space--s(padding-top);
    @include media(m) {
        transform: translateY(calc(100% + #{space()}));
    }
    @include media(l-up) {
        pointer-events: none;
        position: absolute;
        right: 0;
        text-align: right;
        width: 100%;
        z-index: $c-scroll-nav__z-index;
        
        a, button {
            pointer-events: auto;
        }
    }
}
.scroll-nav.fixed {
    @include media(m-up) {
        position: fixed;
        top: 0;
    }
}
.scroll-nav.fixed + .scroll-nav__actions {
    @include media(l-up) {
        position: fixed;
        top: 0;
    }
}

.scroll-nav__wrapper {
    @include o-container();
    
    @include media(m-up) {
        margin-bottom: -$c-scroll-nav__border--width;
    }
}
.scroll-nav__list {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    
    @include media(m-up) {
        @include grid(gutter);
    }
}
.scroll-nav__link {
    @include typo--body(s);
    @include space--s(padding-top, 3/2);
    @include space--s(padding-bottom, 3/2, -4);
    border-bottom-width: $c-scroll-nav__border--width;
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    
    &,
    &:hover,
    &:focus {
        border-bottom-color: transparent;
    }
    &:focus {
        color: inherit;
    }
    
    @include media(m-up) {
        @include space(padding-top);
        @include space(padding-bottom, $addend: -4);
    }
}
.scroll-nav__link--placeholder {
    visibility: hidden;
    
    @include media(s) {
        display: none;
    }
}
.scroll-nav__item.active .scroll-nav__link {
    @include media(m-up) {
        color: $base__color--focus;
        border-bottom-color: $base__color--focus;
    }
}
