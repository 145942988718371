$c-button__border-width: 2px;
$c-button__padding-bump: 4px;
$c-button__padding-bump--s: -6px;
$c-button__padding-bump--l: 3px;
$c-button__padding-bump--x: (6px 7px);
$c-button__padding--y: space--t($addend: $c-button__padding-bump - $c-button__border-width);
$c-button__padding--x: space--s($addend: $c-button__padding-bump - $c-button__border-width);

.button {
    $_text-color: contrasting-color(map-get($base__colors, 'light-gray'), map-get($base__colors, 'white'), $base__color);
    
    @include reset(a button);
    
    background-color: map-get($base__colors, 'light-gray');
    display: inline-block;
    line-height: inherit;
    padding: $c-button__padding--y $c-button__padding--x;
    text-align: left;
    text-transform: uppercase;
    transition: {
        duration: 250ms;
        property: opacity;
    }
    vertical-align: middle;
    
    &,
    &:link,
    &:visited {
        color: $_text-color;
    }
    
    &:disabled {
        opacity: .5;
    }
    
    &.is-active {
        background-color: mix(map-get($base__colors, 'light-gray'), black, 75%);
    }
}

:link,
button,
select,
[type="button"],
[type="submit"] {
    &.button {
        cursor: pointer;
    }
}

select.button {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m407.114 125.916 54.886 55.148-206 205.02-206-205.02 54.887-55.148 151.113 150.395z"/></svg>');
    background-repeat: no-repeat;
    background-position: right $c-button__padding--x center;
    background-size: 1em;
    padding-right: calc(1em + 2 * #{$c-button__padding--x});
}

.button--inline {
    display: inline;
    font-size: inherit;
    font-variant: small-caps;
    line-height: inherit;
    padding: 0 space--s(1/4);
    text-transform: inherit;
}
.button--simple {
    text-transform: inherit;
    transition-property: color, opacity;
}
.button--overlay {
    background-color: map-get($base__colors--overlay, 'steel');
    color: map-get($base__colors, 'white');
    transition-property: background-color, color;
    
    &.is-active {
        background-color: map-get($base__colors--overlay, 'mine-shaft');
    }
    &:hover,
    &:focus {
        color: map-get($base__colors, 'light-gray');
    }
    &:focus {
        outline: none;
    }
}
.button--s {
    padding: {
        bottom: space--s($addend: $c-button__padding-bump--s - $c-button__border-width);
        left: space--s();
        right: space--s();
        top: space--s($addend: $c-button__padding-bump--s);
    }
}
.button--l {
    padding: {
        bottom: space--s($addend: $c-button__padding-bump--l - $c-button__border-width);
        left: space();
        right: space();
        top: space--s($addend: $c-button__padding-bump--l);
    }
}
.button--x {
    padding: {
        bottom: space--s($addend: nth($c-button__padding-bump--x, 1) - $c-button__border-width);
        top: space--s($addend: nth($c-button__padding-bump--x, 1));
    }
    @include media(l-up) {
        padding: {
            bottom: space--s($addend: nth($c-button__padding-bump--x, 2) - $c-button__border-width);
            top: space--s($addend: nth($c-button__padding-bump--x, 2));
        }
    }
}
.button--i {
    background-color: transparent;
    padding: 0;
}
.button--tab.is-active {
    position: relative;
    z-index: 999999;

    &::after {
        position: absolute;
        top: 100%;
        height: 0;
        width: 0;
        left: 50%;
        transform: translateX(-50%);
        border: space() solid transparent;
        border-top-color: inherit;
        content: "";
    }

    &.button--tab--btm::after {
        top: auto;
        bottom: 100%;
        transform: rotate(180deg);
    }
}
@each $_name, $_color in $base__colors {
    $_text-color: contrasting-color($_color, map-get($base__colors, 'white'), $base__color);
    .button--c-#{$_name} {
        background-color: $_color;
        &,
        &:link,
        &:visited {
            color: $_text-color;
        }
        &.is-active {
            background-color: mix($_color, black, 75%);

            &.button--tab {
                background-color: $_color;
            }
        }
        &.button--simple {
            &:not(:disabled) {
                &:not(:hover):not(:focus) {
                    color: rgba($_text-color, .7);
                }
            }
        }
        &.button--tab {
            &,
            &:hover,
            &:focus {
                border-color: $_color;
            }
            &:focus {
                outline: none;
            }
        }
    }
    .button--i-#{$_name} {
        color: $_color;
    }
}
