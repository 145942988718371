/**
 * Remove margin-top and add a fixed margin-bottom on elements
 * which have top and bottom margins by default.
 */
blockquote, dl, fieldset, figure, form, h1, h2, h3, h4, h5, h6, hr, menu, ol, p, pre, ul {
    margin: {
        top: 0;
        bottom: space();
    }
}
/**
 * Remove margin bottom for list elements when children of another list element.
 */
dl dl, dl menu, dl ol, dl ul,
menu dl, menu menu, menu ol, menu ul,
ol dl, ol menu, ol ol, ol ul,
ul dl, ul menu, ul ol, ul ul {
    margin-bottom: 0;
}
